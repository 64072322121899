import React, { useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import { useCart } from "react-use-cart";
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from "react-redux";
import { setOutsideShippingZoneData } from "../store/checkoutData/OutsideShippingZoneData";
import { setDhakaShippingZoneData } from "../store/checkoutData/DhakaShippingZoneData";
import SelectSearch from "react-select-search";
import 'react-select-search/style.css'
import Skeleton from "react-loading-skeleton";
import LoadingBar from "react-top-loading-bar";
import { setCustomerAddressData } from "../store/checkoutData/CustomerAddressData";
import '../assets/Css/checkout.css';
import CoinImage from '../assets/images/Icon/coin.png';


const Checkout = () => {
  const [loading, setLoading] = useState(false);
  const [addressUpdate, setAddressUpdate] = useState(0);
  const dispatch = useDispatch();
  const ref = useRef(null);
  const { ShippingChargesData } = useSelector((state) => state.ShippingChargesData);
  const { DhakaShippingZoneData } = useSelector((state) => state.DhakaShippingZoneData);
  const { OutsideShippingZoneData } = useSelector((state) => state.OutsideShippingZoneData);
  const { CustomerAddressData } = useSelector((state) => state.CustomerAddressData);
  const { UserData } = useSelector((state) => state.UserData);

  let [pointEnableTotalPrice, setPointEnableTotalPrice] = useState(0);

  // const options = [
  //     {name: 'Select Area', value: null},
  //     {name: 'Swedish', value: 'sv'},
  //     {name: 'English', value: 'en'},
  //     {name: 'Afsd', value: 'asd'},
  //     {name: 'AWF', value: 'sad'},
  // ];

  const { emptyCart } = useCart();
  const {
    items,
  } = useCart();
// console.log(items);
  let totalPrice = 0;

  const [customer_id, setCustomerId] = useState(null);

  var customer_name = "";
  // const [userData, setUserData] = useState([]);


  // let userData = [];
  var isLoggedIn = false;
  useEffect(() => {
    if (localStorage.getItem("user") || UserData) {
      var obj = JSON.parse(localStorage.getItem("user"));
      if(UserData){
        obj = UserData;
      }
      setCustomerId(obj.customer_id);
      customer_name = obj.customer_name;
      
        if(obj.customer_id){
          isLoggedIn = true;
        }
      // customer_contact = obj.customer_contact;
      // customer_email = obj.customer_email;
      // console.log(obj);
    }

  }, [customer_id]);

  useEffect(() => {
    axios.get("ec/area-by-district/dhaka")
      .then(resp => {
        dispatch(setDhakaShippingZoneData(resp.data.data.data));
      }).catch(err => {
        console.log(err);
      });
      
      axios.get("ec/get-cities")
      .then(resp => {
        dispatch(setOutsideShippingZoneData(resp.data.data));
      }).catch(err => {
        console.log(err);
      });
      // console.log(UserData);
  }, []);

  const [userData, setUserData] = useState([]);
  useEffect(() => {
    if(customer_id){
    axios
      .get("get-edit-customer/" + customer_id)
      .then(function (resp) {
        setUserData(resp.data);
        if(resp.data.code===401 || resp.data.message === "Authorization token invalid, You cannot proceed"){
          navigate("/login?q=You%20Have%20To%20Login%20Again",{ replace: true });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    }
  }, [customer_id]);
// console.log('user data');
// console.log(userData);
  const [errorList, setError] = useState();
  let navigate = useNavigate();
  let [username, setUsername] = useState("");
  let [email, setEmail] = useState("");
  let [contact, setContact] = useState("");
  let [city, setCity] = useState(null);
  let [area, setArea] = useState(null);
  let [zip, setZip] = useState("");
  let [address, setAddress] = useState("");
  let [orderNote, setOrderNote] = useState("");
  let [paymentMethod, setPaymentMethod] = useState("");
  let [shipingCost, setShipingCost] = useState("0.00");
  let [shipingMethod, setShipingMethod] = useState(false);
  let [vat, setVat] = useState("0.00");
  let [discount, setDiscount] = useState("0.00");
  let [coupon, setCoupon] = useState("");
  let [couponID, setCouponID] = useState(null);
  let [termsAgree, setTermsAgree] = useState(null);
  const [sectionOpen, setSectionOpen] = useState('');
  
  const [appliedPoint, setAppliedPoint] = useState();

  const { pId } = useParams();
  const { qty } = useParams();
  const { attId } = useParams();
  // const [type, setType] = useState("text");
  /* const [selected, setSelected] = useState("yes"); */
  const [product, setProduct] = useState([]);

  const handleTermsAgree = (event) => {
   
  const isChecked = event.target.checked;
  setTermsAgree(isChecked ? true : null);
  };
  
// console.log(pId);
  useEffect(() => {
    
    if(pId){
      async function callApi() {
        const apiResult = await axios.get("get-product/" + pId);
        // console.log(apiResult.data);
        if(apiResult.data.success){
          setProduct(apiResult.data.data);
          if(apiResult.data.data.is_enable_point==1){
            setPointEnableTotalPrice(Number(qty) * Number(apiResult.data.data.final_product_price));
          }
          
  // console.log(pointEnableTotalPrice);
          }
      }
      callApi();
    }
  }, [pId]);
  const [shippingCharges, setShippingCharges] = useState([]);
  const [insideShiCharge, setInsideShiCharge] = useState("");
  const [outsideShiCharge, setOutsideShiCharge] = useState("");

  useEffect(() => {

      setShippingCharges(ShippingChargesData);
      if(ShippingChargesData){
        setInsideShiCharge(ShippingChargesData[0].price);
        setOutsideShiCharge(ShippingChargesData[1].price);
      }

  }, [ShippingChargesData]);

  // console.log(product);

  var products = [];
  if (!pId) {
    items.forEach(function (item) {
      // console.log("listcart theke id"+item.id);
      products.push({
        id: item.id,
        product_id: item.product_id,
        product_name: item.name,
        product_price: Number(item.price).toFixed(2),
        qty: item.quantity,
        image: item.image,
        variation_id: item.variation && item.variation.id,
        variation_value: item.variation && item.variation.value,
        variation_stock: item.variation && item.variation.stock,
        variation_final_price: item.variation && Number(item.variation.attribute_final_price).toFixed(2)
      });
      if(item.is_enable_point==1){
        pointEnableTotalPrice+= item.quantity * item.price;
      }
    });
  }
  else {
      var variation=[];
      if(attId){
        if(product.attributes){
          variation= product.attributes.find(att => att.id == attId);
        }
      }
      if(variation){
        products.push({
        id: product.id,
        product_id: product.id,
        product_name: product.name,
        product_price: Math.ceil(variation.attribute_final_price),
        image: product.image && product.image.small,
        qty: qty,
        variation_id: variation.id,
        variation_value: variation.attribute_value,
        variation_stock: variation.stock,
        variation_final_price: variation.attribute_final_price
      // variation: {
      //   id: variation.id,
      //   value: variation.attribute_value,
      //   stock: variation.stock,
      //   attribute_final_price: variation.attribute_final_price
      // }
      });
      
      }
      else{
        products.push({
          id: product.id,
          product_id: product.id,
          product_name: product.name,
          product_price: Math.ceil(product.final_product_price),
          image: product.image && product.image.small,
          qty: qty
        });
        
      }
    // });
  }

  if (!pId) {
    items.forEach(function (item) {
      totalPrice += item.quantity * item.price;
    });
  }
  else {
    // product.forEach(function (item) {
      totalPrice += qty * Math.ceil(variation.attribute_final_price ? variation.attribute_final_price : product.final_product_price );
    // });
  }
  const [rewardData, setRewardData] = useState();
  const [addresses, setAddresses] = useState([]);
  const [defaultAddress, setDefaultAddress] = useState([]);
 
    const updateAddress = () => {
    if (customer_id) {
            axios
              .get("get-customer-addresses/" + customer_id)
              .then(function (resp) {
                // console.log(resp.data.data);
                dispatch(setCustomerAddressData(resp.data.data));
                setDefaultAddress(resp.data.data.find(address => address.is_default === 1));
              })
              .catch((err) => {
                console.log(err);
              });
            }
      };

  useEffect(() => {
    if (customer_id) {
      if(!CustomerAddressData){
        axios
              .get("get-customer-addresses/" + customer_id)
              .then(function (resp) {
                dispatch(setCustomerAddressData(resp.data.data));
                setAddresses(resp.data.data);
        if(resp.data.data.find(address => address.is_default === 1)){
          setDefaultAddress(resp.data.data.find(address => address.is_default === 1));
          setUsername(resp.data.data.find(address => address.is_default === 1).name);
          setEmail(resp.data.data.find(address => address.is_default === 1).email);
          setContact(resp.data.data.find(address => address.is_default === 1).phone);
          setAddress(resp.data.data.find(address => address.is_default === 1).address);
          setZip(resp.data.data.find(address => address.is_default === 1).zip);
          if(resp.data.data.find(address => address.is_default === 1).shipping_state.id == 14){
            setCity('inside_dhaka');
          }
          else{
            setCity('outside_dhaka');
          }
          setArea(resp.data.data.find(address => address.is_default === 1).area);

        }
        else{
          setDefaultAddress(resp.data.data[0]);
          setUsername(resp.data.data[0].name);
          setEmail(resp.data.data[0].email);
          setContact(resp.data.data[0].phone);
          setAddress(resp.data.data[0].address);
          setZip(resp.data.data[0].zip);
          if(resp.data.data[0].shipping_state.id == 14){
            setCity('inside_dhaka');
          }
          else{
            setCity('outside_dhaka');
          }
          setArea(resp.data.data[0].area);

        }
                
              })
              .catch((err) => {
                console.log(err);
              });
      }
      else {
        setAddresses(CustomerAddressData);
        if(CustomerAddressData.find(address => address.is_default === 1)){
          setDefaultAddress(CustomerAddressData.find(address => address.is_default === 1));
          setUsername(CustomerAddressData.find(address => address.is_default === 1).name);
          setEmail(CustomerAddressData.find(address => address.is_default === 1).email);
          setContact(CustomerAddressData.find(address => address.is_default === 1).phone);
          setAddress(CustomerAddressData.find(address => address.is_default === 1).address);
          setZip(CustomerAddressData.find(address => address.is_default === 1).zip);
          if(CustomerAddressData.find(address => address.is_default === 1).shipping_state.id == 14){
            setCity('inside_dhaka');
          }
          else{
            setCity('outside_dhaka');
          }
          setArea(CustomerAddressData.find(address => address.is_default === 1).area);

        }
        else{
          if(CustomerAddressData[0] && CustomerAddressData[0].name!==undefined){
            setDefaultAddress(CustomerAddressData[0]);
            setUsername(CustomerAddressData[0].name);
            setEmail(CustomerAddressData[0].email);
            setContact(CustomerAddressData[0].phone);
            setAddress(CustomerAddressData[0].address);
            setZip(CustomerAddressData[0].zip);
            if(CustomerAddressData[0].shipping_state.id == 14){
              setCity('inside_dhaka');
            }
            else{
              setCity('outside_dhaka');
            }
            setArea(CustomerAddressData[0].area);

          }

        }
      }
      setAddressUpdate(0);
    }
  }, [defaultAddress, customer_id, addressUpdate]);

  // console.log(defaultAddress);

  const [changeAddress, setChangeAddress] = useState(false);
  const handleAddressChange = () => {
    setChangeAddress(true);
    // console.log("set-customer-default-address/" + defaultAddress.id +"/" + new_default_id);
  }
  
  
  const handleDefaultAddressChange = (new_default_id) => {
    ref.current.continuousStart();
    // console.log("Default Address");
    // console.log(customer_id+"Default Address"+new_default_id);
    axios.put("set-as-default-customer-address/" + customer_id + "/" + new_default_id)
      .then(function (resp) {
        ref.current.complete();
        // console.log(resp.data);
        if (resp.data.success) {
          Swal.fire({
            customClass: {
              icon: 'mt-4'
            },
            position: 'center',
            icon: 'success',
            title: resp.data.message,
            showConfirmButton: false,
            timer: 1000,
          })
          setChangeAddress(false);
          setDefaultAddress([]);
          setAddressUpdate(1);
          updateAddress();
          // dispatch(setCustomerAddressData(null));
          if(pId && qty && attId){
            navigate("/checkout/"+pId+"/"+qty+"/"+attId,{ replace: true });
          }
          else{
            navigate("/checkout",{ replace: true });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
      
  }
  const applyCoupon = () => {
    // console.log('coupon');
    if(coupon){
      ref.current.continuousStart();
      axios
        .post("coupon-apply", {coupon:coupon,sub_total:Number(totalPrice)})
            .then(function (resp) {
              ref.current.complete();
              // console.log(resp.data);
              if (resp.data.success==false) {
                Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: resp.data.message,
                  // showConfirmButton: false,
                  // timer: 2000
                })
              }
              else{
                setDiscount(resp.data.data.coupon_discount);
                setCouponID(resp.data.data.coupon_id);
                setAppliedPoint(null);
              }
            })
            .catch((err) => {
              ref.current.complete();
            });
    }
  };
  // console.log(defaultAddress);
  useEffect(() => {
    if(customer_id){
      // console.log('checkout '+pointEnableTotalPrice);
      axios.post("checkout", {pointEnableTotalPrice:pointEnableTotalPrice})
            .then(resp => {
              console.log(resp.data);
              if(resp.data.success){
                setRewardData(resp.data.data);
              }
              else if(resp.data.code===401 || resp.data.message === "Authorization token invalid, You cannot proceed"){
                navigate("/login?q=You%20Have%20To%20Login%20Again",{ replace: true });
              }
            }).catch(err => {
              console.log(err);
            });

    }
      }, [pointEnableTotalPrice, customer_id]);

  const checkOutSubmit = (event) => {
    ref.current.continuousStart();
    var customer_details = [];
  if (customer_id) {
    customer_details = {
      customer_name: userData.customer_name ?? '',
      customer_email: userData.customer_email ?? '',
      customer_phone: userData.customer_contact ?? '',
      customer_address: userData.customer_address ?? '',
      customer_city: userData.customer_city ?? '',
      customer_zip: userData.zip ?? '',
    }
  }
  else{
  customer_details = {
    customer_name: "",
    customer_email: "",
    customer_phone: "",
    customer_address: "",
    customer_city: "",
    customer_zip: "",
  }
  }
    // event.preventDefault();
    if(!shipingMethod && !paymentMethod) {
      Swal.fire('Select Shipping & Payment Method');
      ref.current.complete()
    }
    else if (city===null || area==null){
      Swal.fire('Select City & Area')
      ref.current.complete()
    }
    else  {
      var customer_city = 0;
      if(city=="inside_dhaka"){
        customer_city = 14;
      }
      else if(city=="outside_dhaka"){
        customer_city = 15;
      }
      var shipping_details = {
        customer_name: username,
        customer_email: email,
        customer_phone: contact,
        customer_address: address,
        customer_city: customer_city,
        customer_zip: zip,
        shipping_area: area,
      };

      if(!customer_details.customer_email){
        customer_details.customer_email="";
      }
      else if(!customer_details.customer_city){
        customer_details.customer_city="";
      }
      else if(!customer_details.customer_phone){
        customer_details.customer_phone="";
      }

      var order = {
        customer_id: customer_id,
        customer_details: customer_details,
        shipping_details: shipping_details,
        products: products,
        order_note: orderNote,
        payment_method: paymentMethod,
        shipping_cost: shipingCost,
        vat: vat,
        coupon_id: couponID,
        order_from: 'online',
        pointApply: appliedPoint ? true : false,
        pointEnableTotalPrice: pointEnableTotalPrice,
        terms_agree: termsAgree
      };
      console.log(order);
      if (paymentMethod === "ssl") {
        // console.log('ssl');
        ref.current.continuousStart();
        axios.post('order', order)
          .then(resp => {
            console.log(resp);
            if (resp.data.success) {
              emptyCart();
              window.location.replace(resp.data.data);
            }
            else if(resp.data.code===401 || resp.data.message === "Authorization token invalid, You cannot proceed"){
              navigate("/login?q=You%20Have%20To%20Login%20Again",{ replace: true });
            }
            else {
              ref.current.complete();
              setError(resp.data.message);
            }
            // window.location.replace(resp.gatewayPageUrl);
          })
          .catch(err => {
            ref.current.complete()
            // console.log('inside catch');
            console.log(err);
            // setError(err.response.data.errors.message);
          });

      }
      else {
        // console.log('cod');
        ref.current.continuousStart();
        axios
          .post("order", order)
          .then(function (resp) {
            console.log(resp);
            var data = resp.data;
            if (resp.data.success) {
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: data.message,
                showConfirmButton: false,
                timer: 1500
              });
              if (!pId) {
                emptyCart();
              }
              if (customer_id) {
                setTimeout(() => {
                navigate("/customer/orders",{ replace: true });
                    }, 1500);
              }
              else {
                setTimeout(() => {
                navigate("/",{ replace: true });
            
                }, 1500);
              }

            }
            else if(resp.data.code===401 || resp.data.message === "Authorization token invalid, You cannot proceed"){
              navigate("/login?q=You%20Have%20To%20Login%20Again",{ replace: true });
            }
            else {
              ref.current.complete();
              Swal.fire({
                position: 'center',
                icon: 'warning',
                title: 'Something went wrong please try again later',
                showConfirmButton: true
              });
              setError(resp.data.message);
            }

          })
          .catch((err) => {
            console.log(err);
            ref.current.complete();
            if (err) {
              setError("There is something wrong in the order!!!");
            }
          });
      }


    }
    // console.log('errorlist');
    // console.log(errorList);
    event.preventDefault();
  };
  // console.log(area);
  return (
    <>
    <LoadingBar
      color='#0098b8' 
      ref={ref}
      // progress={progress}
      // onLoaderFinished={() => setProgress(0)}
    />
    <div className="checkout-page">

      <div className="checkout-content-wrap">
        <div className="container-fluid p-0 p-md-1 p-lg-2">

          <div className="d-block d-sm-none">
            {/* For Mobile version */}
            <center>
              <div className="checkout-logo">
                <Link to="/">
                  <img
                    src={"/assets/images/logo/Original_logo.png"}
                    className="img-fluid"
                    width="150"
                    alt="Ultimate Organic Ecommerce Logo"
                  />
                </Link>
              </div>
            </center>
            <hr />
          </div>
          <div className="row">
            <div className="" id="main-checkout-product-info">
              <div className="row">
                <div className="order-1 order-md-2 col-lg-5 col-md-6 right">

                  <div id="cart-item" className="position-relative">
                    <div className="payment-info-loading" style={{ display: "none" }}>
                      <div className="payment-info-loading-content">
                        <i className="fas fa-spinner fa-spin"></i>
                      </div>
                    </div>

                    <div className="bg-light p-2">
                      <p className="font-weight-bold mb-0">Product(s):</p>
                    </div>
                    <div>
                      <div className="checkout-products-marketplace" id="shipping-method-wrapper">

                        <div className="mt-3 bg-light mb-3">
                          {!pId && (items.map((item, key) => {
                            return (
                              <div className="p-3" key={key}>
                                <div className="row cart-item">
                                  <div className="col-3">
                                    <div className="checkout-product-img-wrapper">
                                      <img
                                        className="item-thumb img-thumbnail img-rounded"
                                        src={item.image}
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                  <div className="col-5">
                                    <p className="mb-0">{item.name}</p>
                                    <p className="mb-0">{item.quantity}x</p>
                                    <p className="mb-0"><b>{item.variation && item.variation.value}</b></p>
                                  </div>
                                  <div className="col-4 text-end">
                                    <p>৳{Number(item.price) * Number(item.quantity)}</p>
                                  </div>
                                </div>
                              </div>
                            );
                          }))}
                          {product.name ? 
                              <div className="p-3">
                                <div className="row cart-item">
                                  <div className="col-3">
                                    <div className="checkout-product-img-wrapper">
                                      <img
                                        className="item-thumb img-thumbnail img-rounded"
                                        src={product.image && product.image.small}
                                        alt="Product Image"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-5">
                                    <p className="mb-0">{product.name}</p>
                                    <p className="mb-0">{qty}x</p>
                                  </div>
                                  <div className="col-4 text-end">
                                    <p>৳{Number(variation.attribute_final_price ? variation.attribute_final_price : product.final_product_price) * Number(qty)}</p>
                                  </div>
                                </div>
                              </div>
                              : (
                                pId && (
                                  <div className="p-3">
                                  <div className="row cart-item">
                                    <div className="col-3">
                                      <div className="checkout-product-img-wrapper">
                                        <Skeleton inline={true} className="item-thumb img-thumbnail img-rounded" width={124} height={124} />
                                      </div>
                                    </div>
                                    <div className="col-5">
                                      <p className="mb-0"><Skeleton /></p>
                                      <p className="mb-0"><Skeleton inline={true} width={50} /></p>
                                    </div>
                                    <div className="col-4 text-end">
                                      <p><Skeleton width={30} /></p>
                                    </div>
                                  </div>
                                </div>
                                )
                              )
                            
                            }
                          <hr />
                          <div className="shipping-method-wrapper p-3">
                            <div className="payment-checkout-form">
                              <div className="">
                                <h6>Shipping Method:</h6>
                                <br />
                              </div>
                              {/* <input type="hidden" name="shipping_option[3]" value="1" /> */}
                              <div id="shipping-method-3">
                                <ul className="list-group list_payment_method">
                                  {/* <li className="list-group-item">
                                    <input
                                      className="magic-radio shipping_method_input"
                                      type="radio"
                                      name="shipping_method[3]"
                                      id="shipping-method-3-default-1"
                                      disabled
                                      value={"0.00"}
                                      // defaultChecked={shipingCost='0.00'}
                                      onClick={(e) => { setShipingCost(e.target.value); setShipingMethod(true); }}
                                    />
                                    <label htmlFor="shipping-method-3-default-1">
                                      Free shipping -<strong>Free shipping</strong>
                                    </label>
                                  </li> */}
                                  <li className="list-group-item">
                                    <input
                                      className="magic-radio shipping_method_input"
                                      type="radio"
                                      name="shipping_method[3]"
                                      id="shipping-method-3-default-2"
                                      value={shippingCharges && city !== null && city === 'outside_dhaka' ? (outsideShiCharge) : (insideShiCharge)}
                                      // value={"80.00"}
                                      // defaultChecked={shipingCost='80.00'}
                                      onClick={(e) => { setShipingCost(e.target.value); setShipingMethod(true); }}
                                      // checked
                                      // defaultChecked={city === "" ? false : true}

                                    />
                                    {ShippingChargesData ? (<label htmlFor="shipping-method-3-default-2">Standard Delivery | ৳{shippingCharges && city !== null && city === 'outside_dhaka' ? (outsideShiCharge) : (insideShiCharge)}</label>)
                                    : (<Skeleton inline={true} width={100} />)}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="payment-method-wrapper p-3">
                            <div className="payment-checkout-form">
                              <div className="mx-0">
                                <h6>Payment method:</h6>
                                <br />
                              </div>
                              <div id="payment-method-3">
                                <ul className="list-group list_payment_method">
                                  <li className="list-group-item">
                                    <input
                                      className="magic-radio payment_method_input"
                                      type="radio"
                                      name="payment_method"
                                      id="cod"
                                      defaultChecked={city !== 'outside_dhaka' && city === 'inside_dhaka' && city !== null}
                                      value={"cod"}
                                      onClick={(e) => setPaymentMethod(e.target.value)}
                                      // checked={city === 'inside_dhaka'}
                                      checked={city === 'outside_dhaka' ? false : null}
                                      disabled={city === 'outside_dhaka'}
                                    />
                                    <label htmlFor="cod">Cash On Delivery</label>
                                  </li>
                                  <li className="list-group-item">
                                    <input
                                      className="magic-radio payment_method_input"
                                      type="radio"
                                      name="payment_method"
                                      id="sslcommerz"
                                      value={"ssl"}
                                      onClick={(e) => setPaymentMethod(e.target.value)}
                                      defaultChecked={city === 'inside_dhaka' || city === 'outside_dhaka'}
                                    />
                                    <label htmlFor="sslcommerz">Sslcommerz</label>
                                  </li>
                                  {/* <li className="list-group-item">
                                    <input
                                      className="magic-radio payment_method_input"
                                      type="radio"
                                      name="payment_method"
                                      id="bkash"
                                      value={"bkash"}
                                      onClick={(e) => setPaymentMethod(e.target.value)}
                                      defaultChecked={city === 'inside_dhaka' || city === 'outside_dhaka'}
                                    />
                                    <label htmlFor="bkash">Bkash</label>
                                  </li> */}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-2 p-2">
                      <div className="row">
                        <div className="col-6">
                          <p>Subtotal:</p>
                        </div>
                        <div className="col-6">
                          <p className="price-text sub-total-text text-end"> ৳{Number(totalPrice).toFixed(2)} </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <p>Shipping fee:</p>
                        </div>
                        <div className="col-6 float-end">
                          <p className="price-text shipping-price-text text-end">৳ {Number(shipingCost).toFixed(2)}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <p>Tax:</p>
                        </div>
                        <div className="col-6 float-end">
                          <p className="price-text tax-price-text text-end">৳ {Number(vat).toFixed(2)}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <p>Discount:</p>
                        </div>
                        <div className="col-6 float-end">
                          <p className="price-text tax-price-text text-end">-৳ {discount ? Number(discount).toFixed(2) : 0.00}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <p>
                            <strong>Total</strong>:
                          </p>
                        </div>
                        <div className="col-6 float-end">
                          <p className="total-text raw-total-text text-end">
                            {" "}
                            ৳{(Number(totalPrice) + Number(shipingCost) + Number(vat) - Number(discount)).toFixed(2)}{" "}
                          </p>
                        </div>
                      </div>
                      {rewardData && (
                      <div className="row">
                        <div className="col-7">
                          <p style={{cursor:'pointer'}} onClick={()=>{
                            if(couponID){
                              Swal.fire({
                                position: 'center',
                                icon: 'warning',
                                title: 'You can apply either coupon or redeem points. To apply you can remove coupon',
                                showConfirmButton: true,
                                // timer: 800
                              })
                            } else {
                              Swal.fire({
                                position: 'center',
                                icon: 'success',
                                title: 'Point redeemed',
                                showConfirmButton: false,
                                timer: 800
                              })
                              setAppliedPoint(Number(rewardData.redeemPoints).toFixed(2));
                              setDiscount(Number(rewardData.discount).toFixed(2));
                              // alert('reddem points');
                            }
                          }}>
                            <strong className="redeem_point_btn"><img className="img-fluid" src={CoinImage} width={15} /> Redeem Points</strong><br/>
                            <small>You are eligible to redeem {Number(rewardData.redeemPoints).toFixed(2)} points</small>
                          </p>
                        </div>
                        <div className="col-5 float-end">
                          <p className="total-text raw-total-text text-end">
                            {" "}
                            -৳{Number(rewardData.discount).toFixed(2) ?? 0.00} <small>(Eligible if redeemed)</small><br/>
                            <small>Point discount amount</small> </p>
                        </div>
                      </div>
                      )}
                    </div>
                  </div>
                  <hr />
                  {couponID && (
                    <>
                    <p className="ps-3">Applied Coupon: <span className="text-warning"><b>{coupon}</b></span><Link className="ms-3" to={'#'}
                    onClick={() => {setCoupon('');setCouponID(null);setDiscount('');} }>Remove</Link></p>
                    <hr/>
                    </>
                  )}
                  {appliedPoint && (
                    <>
                    <p className="ps-3">Point Applied: <span className="text-warning"><b>{appliedPoint}</b></span><Link className="ms-3" to={'#'}
                    onClick={() => {setAppliedPoint('');setCoupon('');setDiscount('');} }>Remove</Link></p>
                    <hr/>
                    </>
                  )}
                  <div className="mt-3 mb-5">
                    <div className="checkout-discount-section mb-2">
                      <Link to="#" className="ps-3 btn-open-coupon-form" onClick={()=>{
                        if(appliedPoint){
                          Swal.fire({
                            position: 'center',
                            icon: 'warning',
                            title: 'You can apply either coupon or redeem points. To apply you can remove the redeemed points',
                            showConfirmButton: true,
                            // timer: 800
                          })
                        }
                        else{
                          sectionOpen==='coupon' ? setSectionOpen('') : setSectionOpen('coupon')}}
                        }
                        >
                        You have a coupon code?
                      </Link> 
                    </div>
                    <div className="coupon-wrapper">
                      {sectionOpen==='coupon' && (

                          <div className="row promo coupon coupon-section">
                          <div className="col-lg-8 col-md-8 col-8">
                            <input
                              type="text"
                              name="coupon_code"
                              className="form-control coupon-code input-md checkout-input"
                              value={coupon}
                              placeholder="Enter coupon code..."
                              onChange={(e) => setCoupon(e.target.value)}
                            />
                            <div className="coupon-error-msg">
                              <span className="text-danger"></span>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4 col-4 text-end">
                            <button
                              className="btn btn-md btn-gray btn-info apply-coupon-code float-end"
                              data-url=""
                              type="button"
                              style={{ marginTop: "0", padding: "10px 20px" }}
                              onClick={() => {applyCoupon();}}
                            >
                              <i className="fi-rs-label mr-10"></i>Apply
                            </button>
                          </div>
                          </div>
                      )} 
                    </div>
                    <div className="clearfix"></div>
                    {ShippingChargesData && (
                    <button
                      type="submit"
                      className="btn payment-checkout-btn payment-checkout-btn-step m-3 d-block d-md-none"
                      data-processing-text="Processing. Please wait..."
                      data-error-header="Error"
                      onClick={(event) => checkOutSubmit(event)}  
                    >
                      Place Order
                    </button>
                    )}
                  </div>
                </div>
                <div className="col-lg-7 col-md-6 left p-0">
                  <div className="d-none d-sm-block">
                    <div className="checkout-logo">
                      <div className="">
                        <Link to="/">
                          <img
                            src={"/assets/images/logo/Original_logo.png"}
                            className="img-fluid"
                            width="150"
                            alt="Ecommerce"
                          />
                        </Link>
                      </div>
                    </div>
                    <hr />
                  </div>
                  {changeAddress ? (
                    <div id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab" className="tab-pane fade active show">
                      {addresses &&
                        addresses.map((address) => {
                          return (
                              <div className="row p-2">
                                <div className="col-lg-12 dashboard-address-item  is-address-default ">
                                  <div className="card h-100 mb-3 mb-lg-0 mb-2">
                                    <div className="card-header">
                                      <h5 className="mb-0">
                                        {address.name} &nbsp;
                                        {address.is_default === '1' ? (
                                          <small className="badge bg-primary" style={{ float: 'right' }}>Default</small>
                                        ) : (
                                          <button style={{ float: 'right' }} className="btn" onClick={() => handleDefaultAddressChange(address.id)}>Select This Address</button>
                                        )}
                                      </h5>
                                    </div>{" "}
                                    <div className="card-body p-0">
                                      <address className='row'>
                                        <p className="col-6">Phone: {address.phone}</p>
                                        <p className="col-6">Email: {address.email}</p>
                                        <p className="col-6">Address: {address.address}</p>
                                        <p className="col-6">Area: {address.area}</p>
                                        <p className="col-6">City: {address.shipping_state.name}</p>
                                        <p className="col-6">Zip: {address.zip}</p>
                                      </address>{" "}
                                    </div>{" "}
                                  </div>
                                </div>{" "}
                              </div>
                          );
                        })}
                      <button className="btn btn-warning m-3" onClick={() => { setChangeAddress(false); }}>Go Back</button>
                    </div>
                  ) : (
                    <div>
                      {CustomerAddressData && defaultAddress.id ? (
                        //Logged in shipping information
                        <div className="card">
                          <h5 className="checkout-payment-title p-4">Shipping information</h5>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="card h-100 mb-3 mb-lg-0 mb-2">
                                <div className="card-header">
                                  <h5 className="mb-0">
                                    {defaultAddress.name} &nbsp;
                                    <small className="badge bg-primary">Default</small>
                                  </h5>
                                </div>{" "}
                                <div className="card-body">
                                  <address className="row">
                                    <p className="col-6">Phone: {defaultAddress.phone}</p>
                                    <p className="col-6">Email: {defaultAddress.email}</p>
                                    <p className="col-6">Address: {defaultAddress.address}</p>
                                    <p className="col-6">Area: {defaultAddress.area}</p>
                                    <p className="col-6">City: {defaultAddress && defaultAddress.shipping_state.name}</p>
                                    <p className="col-6">Zip: {defaultAddress.zip}</p>
                                    <div className="col-6">
                                      <button className="btn mt-3" onClick={() => handleAddressChange()}>Change Address</button>
                                    </div>
                                    <div className="col-6">
                                      <Link to={'/customer/add-address'} className="mt-3 btn">Add New Address</Link>
                                    </div>
                                  </address>{" "}
                                </div>{" "}
                              </div>
                            </div>{" "}
                          </div>{" "}
                          <form className="p-4" onSubmit={(e) => { checkOutSubmit(e); }}>
                            <div className="form-group mb-3">
                              <label htmlFor="description" className="control-label">
                                Order notes
                              </label>
                              <br />
                              <textarea
                                name="description"
                                id="description"
                                className="form-control"
                                rows={4}
                                placeholder="Notes about your order, e.g. special notes for delivery."
                                value={orderNote}
                                onChange={(e) => setOrderNote(e.target.value)}
                              />
                            </div>
                            <div className="form-check ms-2 mb-3">
                            <input
                            className="form-check-input"
                            required
                            id="terms_agree"
                            type="checkbox"
                            name="terms_condition"
                            value="agree"
                            onChange={handleTermsAgree}
                          />
                        <label  htmlFor="terms_agree" className="control-label ps-2">
                        I agree to the <Link to={'/terms-conditions'} >Terms and Conditions</Link></label>
                        </div>
                            <div className="form-group mb-3">
                              <div className="row">
                                <div className="col-md-6" style={{ lineHeight: "53px" }}>
                                  <Link to="/cart" className="text-info">
                                    <i className="fas fa-long-arrow-alt-left"></i> <span className="d-inline-block back-to-cart">Back to cart</span>
                                  </Link>
                                </div>
                                <div className="col-md-6 checkout-button-group d-none d-md-block">
                                  {ShippingChargesData && (
                                  <button
                                    type="submit"
                                    className="btn payment-checkout-btn payment-checkout-btn-step float-end"
                                    data-processing-text="Processing. Please wait..."
                                    data-error-header="Error"
                                  >
                                    Place Order
                                  </button>
                                  )}
                                </div>
                              </div>

                              {/* <div className="d-block d-md-none back-to-cart-button-group">
                                <Link className="text-info" to="/cart">
                                  <i className="fas fa-long-arrow-alt-left"></i> <span className="d-inline-block">Back to cart</span>
                                </Link>
                              </div> */}
                            </div>
                          </form>
                        </div>

                      ) : (
                        isLoggedIn && (
                          <div className="card"><h5 className="checkout-payment-title p-4">Shipping information</h5>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="card h-100 mb-3 mb-lg-0 mb-2">
                              <div className="card-header">
                                <h5 className="mb-0">
                                  <Skeleton inline={true} width={'25%'} /> <Skeleton inline={true} width={'10%'} />
                                </h5>
                              </div>{" "}
                              <div className="card-body">
                                <address className="row">
                                  <p className="col-6"> <Skeleton inline={true} /> </p>
                                  <p className="col-6"> <Skeleton inline={true} /> </p>
                                  <p className="col-6"> <Skeleton inline={true} /> </p>
                                  <p className="col-6"> <Skeleton inline={true} /> </p>
                                  <p className="col-6"> <Skeleton inline={true} /> </p>
                                  <p className="col-6"> <Skeleton inline={true} /> </p>
                                  <div className="col-6">
                                    <Skeleton inline={true} width={100} height={35} />
                                  </div>
                                  <div className="col-6">
                                  <Skeleton inline={true} width={150} height={35} />
                                  </div>
                                </address>{" "}
                              </div>{" "}
                            </div>
                          </div>{" "}
                        </div>{" "}
                        </div>
                        )
                      ) }
                    </div>
                  )}
                  {!CustomerAddressData && !customer_id && !isLoggedIn && (
                    //outside users
                    <form onSubmit={(e) => { checkOutSubmit(e); }}>
                      <div className="form-checkout">
                        <div>
                          <h5 className="checkout-payment-title">Shipping information</h5>
                          <div className="customer-address-payment-form">
                            <div className="form-group mb-3">
                              <p>
                                Already have an account? <Link to="/login">Login</Link>
                              </p>
                            </div>
                            <div className="address-form-wrapper">
                              <div className="row">
                                <div className="col-12">
                                  <div className="form-group mb-3 ">
                                    <input
                                      type="text"
                                      name="name"
                                      id="name"
                                      placeholder="Full Name*"
                                      className="form-control address-control-item address-control-item-required checkout-input"
                                      required
                                      value={username}
                                      onChange={(e) => setUsername(e.target.value)}
                                    />
                                    {errorList && (
                                    <span className="text-danger">
                                      {errorList['shipping_details.customer_name'] && errorList['shipping_details.customer_name'][0]}
                                    </span>
                                  )}
                                    
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-8 col-12">
                                  <div className="form-group  ">
                                    <input
                                      type="text"
                                      name="email"
                                      id="address_email"
                                      placeholder="Email"
                                      className="form-control address-control-item address-control-item-required checkout-input"
                                      value={email}
                                      onChange={(e) => setEmail(e.target.value)}
                                    />
                                  {errorList && (
                                    <span className="text-danger">
                                      {errorList['shipping_details.customer_email'] && errorList['shipping_details.customer_email'][0]}
                                    </span>
                                  )}
                                  </div>
                                </div>
                                <div className="col-lg-4 col-12">
                                  <div className="form-group  ">
                                    <input
                                      type="text"
                                      name="address[phone]"
                                      id="address_phone"
                                      placeholder="Phone "
                                      required
                                      className="form-control address-control-item address-control-item-required checkout-input"
                                      value={contact}
                                      onChange={(e) => setContact(e.target.value)}
                                    />
                                    {errorList && (
                                    <span className="text-danger">
                                      {errorList['shipping_details.customer_phone'] && errorList['shipping_details.customer_phone'][0]}
                                    </span>
                                  )}
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-6 col-12">
                                  <div className="form-group mb-3">
                                    <select style={{ height: '64px' }} className="form-control address-control-item address-control-item-required"
                                      name="city"
                                      value={city}
                                      required
                                      onChange={(e) => { setCity(e.target.value); if (e.target.value === 'inside_dhaka') { setShipingCost(insideShiCharge); } else if (e.target.value === 'outside_dhaka') { setShipingCost(outsideShiCharge); setPaymentMethod(''); } else { setShipingCost('0.00'); } }}
                                    >
                                      <option value={null}>Your City</option>
                                      <option value={'inside_dhaka'}>Inside Dhaka</option>
                                      <option value={'outside_dhaka'}>Outside Dhaka</option>
                                    </select>
                                    {errorList && (
                                    <span className="text-danger">
                                      {errorList['shipping_details.customer_city'] && errorList['shipping_details.customer_city'][0]}
                                    </span>
                                  )}
                                  </div>
                                </div>
                                <div className="col-sm-6 col-12">
                                  <div className="form-group">
                                    <input
                                      id="address_city"
                                      type="text"
                                      className="form-control address-control-item address-control-item-required checkout-input"
                                      placeholder="Zip"
                                      name="zip"
                                      value={zip}
                                      onChange={(e) => setZip(e.target.value)}
                                    />
                                  </div>
                                </div>
                                <div className="col-12">
                                  {city === 'inside_dhaka' && (
                                    // <div className="form-group">
                                    // <SelectSearch options={DhakaShippingZoneData} value={area} search={true} name="area" placeholder="Select Area" onChange={(selectedValue,selectedOption) => {setArea(selectedOption.name);console.log(selectedOption);}} />
                                    // </div>
                                    DhakaShippingZoneData ? (
                                    <div className="form-group ">
                                      <select style={{ height: '64px' }} className="form-control address-control-item address-control-item-required"
                                        name="area"
                                        required={area === "" ? true : false}
                                        value={area}
                                        onChange={(e) => setArea(e.target.value)}
                                      >
                                        <option value={null}>Select Area</option>
                                        {DhakaShippingZoneData &&
                                            (DhakaShippingZoneData.map((srvzn, index) => {
                                                return <option key={index} value={srvzn.name}>{srvzn.name}</option>;
                                            }))}
                                      </select>
                                      {errorList && (
                                    <span className="text-danger">
                                      {errorList['shipping_details.area'] && errorList['shipping_details.area'][0]}
                                    </span>
                                  )}
                                    </div>
                                    ) : (
                                      <><Skeleton width={'100%'} height={65} /><br/></>
                                    )
                                  )}
                                  {city === 'outside_dhaka' && (
                                    OutsideShippingZoneData ? (
                                    <div className="form-group">
                                    <SelectSearch options={OutsideShippingZoneData} value={area} search={true} name="area" placeholder="Select Area" onChange={(selectedValue) => {setArea(selectedValue);}} />
                                    </div>
                                    ) : (
                                      <><Skeleton width={'100%'} height={65} /><br/></>
                                    )
                                  )}
                                </div>
                                <div className="col-12">
                                  <div className="form-group mb-3 ">
                                    <input
                                      id="address_address"
                                      type="text"
                                      className="form-control address-control-item address-control-item-required checkout-input"
                                      placeholder="Address"
                                      name="address"
                                      required
                                      value={address}
                                      onChange={(e) => setAddress(e.target.value)}
                                    />
                                    {errorList && (
                                    <span className="text-danger">
                                      {errorList['shipping_details.customer_address'] && errorList['shipping_details.customer_address'][0]}
                                    </span>
                                  )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div className="form-group mb-3">
                          <label htmlFor="description" className="control-label">
                            Order notes
                          </label>
                          <br />
                          <textarea
                            name="description"
                            id="description"
                            className="form-control"
                            rows={4}
                            placeholder="Notes about your order, e.g. special notes for delivery."
                            value={orderNote}
                            onChange={(e) => setOrderNote(e.target.value)}
                          />
                        </div>
                        <div className="form-check ms-2 mb-3">
                        <input
                            className="form-check-input"
                            required
                            id="terms_agree"
                            type="checkbox"
                            name="terms_condition"
                            value="agree"
                            onChange={handleTermsAgree}
                          />
                        {errorList && (
                                    <span className="text-danger">
                                      {errorList['terms_agree']}
                                    </span>
                                  )}
                        <label htmlFor="terms_agree" className="control-label ps-2">
                        I agree to the <Link to={'/terms-conditions'} >Terms and Conditions</Link>, <Link to="/privacy-policy">
                          <span>Privacy Policy</span>
                        </Link>,
                        <Link to="/shipping-delivery">
                          <span> Shipping &amp; Delivery</span>
                        </Link>
                        ,
                        <Link to="/returns-exchanges">
                          <span> Returns &amp; Exchanges</span>
                        </Link>
                        
                        </label>
                        </div>
                        <div className="form-group mb-3">
                          <div className="row">
                            <div className="col-md-6 d-none d-md-block" style={{ lineHeight: "53px" }}>
                              <Link to="/cart" className="text-info">
                                <i className="fas fa-long-arrow-alt-left"></i> <span className="d-inline-block back-to-cart">Back to cart</span>
                              </Link>
                            </div>
                            <div className="col-md-6 checkout-button-group">
                              {ShippingChargesData && (
                              <button
                                type="submit"
                                className="btn payment-checkout-btn payment-checkout-btn-step float-end  d-none d-md-block"
                              >
                                Place Order
                              </button>
                              )}
                            </div>
                          </div>

                          <div className="d-block d-md-none back-to-cart-button-group">
                            <Link className="text-info" to="/cart">
                              <i className="fas fa-long-arrow-alt-left"></i> <span className="d-inline-block">Back to cart</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  ) }
                   {customer_id && !CustomerAddressData && 
                      (
                        <div className="form-checkout">
                          <div className="col-12">
                              <div className="alert alert-dark d-flex align-items-center" role="alert">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  fill="currentColor"
                                  className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
                                  viewBox="0 0 16 16"
                                  role="img"
                                  aria-label="Info:"
                                >
                                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                                </svg>
                                <div>
                                 <h6>Dear {customer_name} You did not added any address yet. Add address first!</h6>
                                </div>
                              </div>
                            </div>

                            <div className="col-12 m-2">
                              <Link className="add-address" to="/customer/add-address">
                                <i className="fa fa-plus"></i>
                                <Link to={'/customer/add-address'} className="mt-3 btn">Add New Address</Link>
                              </Link>
                            </div>
                          {/* <h6>Dear {customer_name} You haven't added any address yet. Add address first</h6>
                        <Link to={'/customer/add-address'} className="mt-3 btn">Add New Address</Link> */}
                          </div>
                      )
                   }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
    </div>
    </>
  );
};

export default Checkout;